import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo_footer from "../assets/Logo_Footer.png";
import devicesFooter from "../assets/devicesFooter.png";
import arrow_up from "../assets/Arrow_Up.svg";
import social_fb from "../assets/Social_Fb.svg";
import social_ig from "../assets/Social_IG.svg";
import footerWithCircle from "../assets/footerWithCircle.png";
import social_youtube from "../assets/Social_Youtube.svg";
import DownloadLinks from "./DownloadLinks";
import { Trans, useTranslation } from "react-i18next";
import SignUpLink from "./SignUpLink";
import config from "../config.json";

function Footer({ footerImage }) {
  const { t, i18n } = useTranslation();
  const helpCenterLink =
    config.helpCenter[i18n.language] || config.helpCenter.en;

  // const businessTypes =   {
  //   name: t("Business Types"),
  //   link: "",
  //   childrenHasLinks: true,
  //   children: [
  //     {
  //       name: t("Food and Beverage"),
  //       links: [
  //         {
  //           name: t("Fastfood and Restaurant"),
  //           link: "/business-types/fastfood-beverages/fastfood-and-restaurant",
  //         },
  //         {
  //           name: t("Cafe/Cofee Shop"),
  //           link: "/business-types/fastfood-beverages/cafe-coffee-shop",
  //         },
  //         {
  //           name: t("Bars and Breweries"),
  //           link: "/business-types/fastfood-beverages/bars-breweries",
  //         },
  //         {
  //           name: t("Food Service/Catering"),
  //           link: "/business-types/fastfood-beverages/food-service-catering",
  //         },
  //       ],
  //     },
  //     {
  //       name: t("Retail"),
  //       links: [
  //         {
  //           name: t("Arts / Carfts / Novelty"),
  //           link: "/business-types/retail/arts-crafts-novelty",
  //         },
  //         {
  //           name: t("Vehicle Supplies and Parts"),
  //           link: "/business-types/retail/vehicles-supplies-part",
  //         },
  //         {
  //           name: t("General Merchandise"),
  //           link: "/business-types/retail/general-merchandise",
  //         },
  //         {
  //           name: t("Grocery Store"),
  //           link: "/business-types/retail/grocery-store",
  //         },
  //         {
  //           name: t("Furniture"),
  //           link: "/business-types/retail/furniture",
  //         },
  //       ],
  //     },
  //     {
  //       name: t("Beauty"),
  //       links: [
  //         {
  //           name: t("Hair Care"),
  //           link: "/business-types/beauty/hair-care",
  //         },
  //         {
  //           name: t("Skincare and Spa"),
  //           link: "/business-types/beauty/skincare-and-spa",
  //         },
  //         {
  //           name: t("Nail Care"),
  //           link: "/business-types/beauty/nail-care",
  //         },
  //         {
  //           name: t("Wellness and Holistic Beauty"),
  //           link: "/business-types/beauty/wellness-holistic",
  //         },
  //         {
  //           name: t("Tattoos and Piercing"),
  //           link: "/business-types/beauty/tattoo-piercing",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Services",
  //       links: [
  //         {
  //           name: t("Professional Services"),
  //           link: "/business-types/services/proffesional-services",
  //         },
  //         {
  //           name: t("Home and Maintenance Services"),
  //           link: "/business-types/services/home-maintenance-services",
  //         },
  //         {
  //           name: t("Technology and IT Services"),
  //           link: "/business-types/services/technology-and-it-services",
  //         },
  //         {
  //           name: t("Event Planning and Entertainment "),
  //           link: "/business-types/services/event-planning-entertainment-services",
  //         },
  //         {
  //           name: t("Education and Training Services"),
  //           link: "/business-types/services/education-training-services",
  //         },
  //         {
  //           name: t("Automotive Services"),
  //           link: "/business-types/services/automotive-services",
  //         },
  //       ],
  //     },
  //   ],
  // }

  const businessTypes = [
    {
      title: t("Retail"),
      link: "/business-types/retail",
    },
    {
      title: t("Professional Services"),
      link: "/business-types/professional-services",
    },
    {
      title: t("Food and Beverage"),
      link: "/business-types/food-and-beverages",
    },
    {
      title: t("Digital Products"),
      link: "/business-types/virtual-products",
    },
    {
      title: t("Rental Business"),
      link: "/business-types/rental-services",
    },
  ];

  const products = [
    {
      title: t("Point of Sale"),
      link: "/products/pos",
    },
    {
      title: t("Inventory Management"),
      link: "/products/inventory-management",
    },
    {
      title: t("Online Store"),
      link: "/products/online-store",
    },
    {
      title: t("Employee Management"),
      link: "/products/employee-payroll-management",
    },
    {
      title: t("Payroll"),
      link: "/products/payroll",
    },
    // {
    //   title: t("Cash and Credit Book"),
    //   link: "/products/cash-credit-book",
    // },
  ];

  const otherLinks = [
    {
      title: t("Why"),
      link: "/why-dizlog",
    },
    // {
    //   title: t("Partner"),
    //   link: "/partner-program",
    // },
    {
      title: t("Pricing"),
      link: "/pricing",
    },
    {
      title: t("Contact Us"),
      link: "/contact-us",
    },
    {
      title: t("Privacy Policy"),
      link: "/privacy-policy",
    },
    {
      title: t("Term & Conditions"),
      link: "/terms-and-conditions",
    },
  ];

  const resources = [
    {
      title: t("FAQ"),
      link: "/get-help",
    },
    {
      title: t("Logistics"),
      link: "/logistics-partners",
    },
    {
      title: t("Regional Offices"),
      link: "/regional-offices",
    },
    {
      title: t("Payments"),
      link: "/payment-partners",
    },
  ];

  return (
    <footer className="footer">
      {/* <div 
        id="download" 
        className="min-h-[340px] flex bg-[#FEEDD3] flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row"
      >
        <div className="flex flex-1 items-end justify-center">
          <div className="download-img bg-red-500">
          <img src={footerImage} alt="intro-info-img"/>
          <img
            src={footerWithCircle}
            alt="intro-info-img"
            className="w-full lg:w-[629px] h-[90%]"
          />
        </div>
        <div 
          className="flex flex-1 pt-4 xl:pt-0 lg:pt-0 md:pt-0 sm:pt-0 pl-2 xl:pl-0 lg:pl-0 md:pl-0 sm:pl-0 flex-col justify-center items-start"
        >
          <span 
            className="font-[Raleway] font-[700] text-base xl:text-[32px] lg:text-[32px] md:text-[32px] sm:text-[32px] text-[#00215D]"
          >
            <Trans>Download the DizLogApp Now!</Trans>
          </span>
          <span 
            className="font-[Raleway] text-[#00215D] text-base xl:text-[18px] lg:text-[18px] md:text-[18px] sm:text-[18px] mt-2 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-0  mb-2 font-[700]"
          >
            {t("Compatible with iPhone, iPad, Android smartphones, or tablets.")}
          </span>
          {window.location.hostname.endsWith(".my") ? (
            <SignUpLink alternative={true} />
          ) : (
            <DownloadLinks />
          )}
        </div>
      </div> */}

      <div className="flex flex-col items-center bg-[#1B2B33] py-6 px-4">
        <div className="flex flex-row flex-wrap sm:flex-no-wrap justify-between w-full max-w-4xl gap-4">
          <div className="flex flex-col">
            <h3 className="helpful-links-header mb-2">{t("Business Types")}</h3>
            <ul>
              {businessTypes.map((item, index) => (
                <li className="">
                  <Link
                    className="footer-option-links flex flex-col no-underline hover:text-primary-900"
                    to={item.link}
                    key={index}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="the-app-header mb-2">{t("Products")}</h3>
            <ul>
              {products.map((item, index) => (
                <li className="">
                  <Link
                    className="footer-option-links flex flex-col no-underline hover:text-primary-900"
                    to={item.link}
                    key={index}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="helpful-links-header mb-2">{t("Other Links")}</h3>
            <ul>
              {otherLinks.map((item, index) => (
                <li className="">
                  <Link
                    className="footer-option-links flex flex-col no-underline hover:text-primary-900"
                    to={item.link}
                    key={index}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col">
            <h3 className="helpful-links-header mb-2">{t("Resources")}</h3>
            <ul>
              {resources.map((item, index) => (
                <li className="">
                  <Link
                    className="footer-option-links flex flex-col no-underline hover:text-primary-900"
                    to={item.link}
                    key={index}
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="social-icons mt-4">
              <a
                href="https://www.facebook.com/diglogapp/"
                target="_blank"
                className="icon"
              >
                <img src={social_fb} alt="facebook-icon" />
              </a>
              <a
                href="https://www.instagram.com/dizlogpos"
                target="_blank"
                className="icon"
              >
                <img src={social_ig} alt="instagram-icon" />
              </a>
              <a
                href="https://youtube.com/channel/UC_YTCyjI2kIIxKc6K6U4aVw"
                target="_blank"
                className="icon"
              >
                <img src={social_youtube} alt="youtube-icon" />
              </a>
              {/**<img className='icon' src={social_twitter} alt="twitter-icon"/>**/}
            </div>
            <a
              href="#header"
              className="font-bold mt-4 px-3 py-2 text-xs text-white border-2 border-solid border-white flex flex-row gap-2 rounded-md no-underline"
            >
              {t("Back to Top")}{" "}
              <img src={arrow_up} alt="arrow-up" className="w-[0.6rem]" />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-[#142127] text-[#FFFFFF] font-normal text-[12px] text-center py-2">
        {t("Copyright 2024 DizLog All Rights Reserved")}
      </div>
    </footer>
  );
}

export default Footer;
